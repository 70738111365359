import React from 'react';
import './CompanyIntro.css';

function CompanyIntro() {
    return (
        <div className="company-intro">
            <div className="content1">
                <p className="intro">
                    该估值系统由温州市价值引擎企业管理咨询有限责任公司开发。本公司主要从事两类业务：
                </p>
                <ul className="services">
                    <li>
                        <span className="bullet">◆</span>
                        <span className="service-content">
                            财富管理咨询：
                            帮助投资者评估财富状况、确定财富目标、制定财富规划、配置资产类别、构造投资组合、动态风险管理。
                        </span>
                    </li>
                    <li>
                        <span className="bullet">◆</span>
                        <span className="service-content">
                            创业融资咨询：
                            协助创业者和投资者利用AI驱动的iMOVE估值模型评估
                            企业价值，确保各方利益，优化融资关系。

                        </span>
                    </li>
                </ul>
                <div className="contact-info">
                    <p>公司地址：浙江省温州市瓯海经济开发区东方南路50号温州市国家大学科技园1号楼5楼5010室</p>
                    <p>电子邮箱：valuengine@163.com</p>
                    <p>联系电话：18688014905、15988760759</p>
                </div>
            </div>
        </div>
    );
}

export default CompanyIntro;