import React from 'react';
import './Footer.css';

function Footer() {
    return (
        <footer className="footer">
            <a href="https://beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer">
                浙ICP备2024135862号
            </a>
        </footer>
    );
}

export default Footer;