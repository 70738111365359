import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Home from './pages/Home/index.tsx';
import Theory from './pages/Theory/index.tsx';
import CompanyIntro from './pages/CompanyIntro/index.tsx';
import Entrepreneur from './pages/Entrepreneur/index.tsx';
import Investor from './pages/Investor/index.tsx';
import Result from './pages/Result/index.tsx';

function App() {
    const [s, setS] = useState(true);


    return (
        <div className="App">
            <Router>
                <Routes>
                    {/* 结果页面不包含 Header */}
                    <Route path="/result" element={<Result />} />
                    <Route path="/entrepreneur" element={<Entrepreneur />} />
                    <Route path="/investor" element={<Investor />} />
                    {/* 其他页面包含 Header */}
                    <Route
                        path="*"
                        element={
                            <>
                                <Header />
                                <div className="content">
                                    <Routes>
                                        <Route path="/" element={<Home />} />
                                        <Route path="/theory" element={<Theory />} />
                                        <Route path="/company-intro" element={<CompanyIntro />} />

                                    </Routes>
                                </div>
                            </>
                        }
                    />
                </Routes>
            </Router>
        </div>

    );
}

export default App;