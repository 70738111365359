import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../assets/logo.png';
import './Header.css';

function Header() {
    const location = useLocation();

    // 检查当前路径
    const isActive = (path) => {
        return location.pathname === path;
    }

    return (
        <header className="header">
            <img src={logo} alt="Valuengine Logo" className="logo" />
            <nav>
                <Link to="/" className={isActive('/') ? 'active' : ''}>
                    企业估值
                </Link>
                <Link to="/theory" className={isActive('/theory') ? 'active' : ''}>
                    理论基础
                </Link>
                <Link to="/company-intro" className={isActive('/company-intro') ? 'active' : ''}>
                    公司简介
                </Link>
            </nav>
        </header>
    );
}

export default Header;