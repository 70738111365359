import React from 'react';
import './Theory.css';

function Theory() {
    const papers = [
        { id: 1, title: "“风险投资介入对企业创新的影响研究”，《温州大学学报(社会科学版)》，2024（7.11日网络首发），pp1-16。" },
        { id: 2, title: "“风险投资进入能提升创业型企业的创新绩效吗？”，《科学学与科学技术管理》，2021，第5期，pp32-50。" },
        { id: 3, title: "“创业型企业动态估值与内生股权配置——一种合作博弈方法”，《制度经济学研究》，2021，第1期，pp175-189。" },
        { id: 4, title: "“Uncertainty, Venture Capital and Entrepreneurial Enterprise Innovation—Evidence from Listed Companies on China GEM”, Pacific-Basin Finance Journal, Vol.68, 101576, doi.org/10.1016/j.pacfin.2021.101576." },
        { id: 5, title: "“Innovation Capacity and Entrepreneurial Intention: The Moderating Effects of Entrepreneurial Atmosphere”，The Journal of Applied Business and Economics, 2018, Vol.20(1), pp68-84." },
        { id: 6, title: "“奈特不确定性下创业型企业的分阶段融资机制”，《制度经济学研究》，2018，第2期，pp108-129。" },
        { id: 7, title: "“创新能力、不确定性容忍度与创业倾向”，《科研管理》，2018，第39卷，S1期，pp226-235。" },
        { id: 8, title: "“企业家激励、隐性契约与创业型企业融资”，《制度经济学研究》，2017，第2期，pp64-86。" },
        { id: 9, title: "“创新不确定性、动态契约与创业型企业融资”，《制度经济学研究》，2016，第2期，pp1-27。" },
        { id: 10, title: "“企业家才能-创新机会匹配对创新溢价的影响——来自中国创业板上市公司的证据”，《汕头大学学报》（社科版），2014，第4期，pp42-47。" },
        { id: 11, title: "“奈特不确定性下企业家创新的微观决策——基于四种公理化模型的比较分析”，《制度经济学研究》，2012，第2期，pp73-96。" },
        { id: 12, title: "“企业家才能与创新机会的动态匹配模型——对企业家创新决策的含义”，《南方经济》，2011，第8期，pp:64-74。" },
        { id: 13, title: "“企业家才能的定价问题”，《制度经济学研究》，2011，第2期，pp138-161。" },
        { id: 14, title: "“企业家的创新活动：本质及含义”，《制度经济学研究》，2007，Vol.16，pp52-64。" },
        { id: 15, title: "“经济学中的不确定性和有限理性”，《经济评论》，2007，第6期，pp37-41。" },
        { id: 16, title: "“企业家活动与创新利润的来源”，《财经论丛》，2007，第4期，pp14-20,转载于《高等学校文科学术文摘》2007.5。" },
    ];

    const books = [
        { id: 1, title: "《创业型企业的动态融资机制》，经济科学出版社，2021。" },
        { id: 2, title: "“企业家活动，创新激励与经济周期”，载《中国经济周期研究报告》[刘树成 主编]，社会科学文献出版社，2006，pp:377-392。" },
    ];

    const projects = [
        { id: 1, title: "国家社会科学基金项目：《基于动态契约的创业型企业融资与风险控制研究》（立项号：15BJY162）（2015.6-2018.6）" },
        { id: 2, title: "广东省哲学社会科学“十二五”规划项目：《创业型企业定价与融资机制研究》（立项号：GD12YYJ03）（2013.1-2014.12）" },
        { id: 3, title: "广东省自然科学基金项目：《企业家创新活动的微观决策过程与机制》（立项号：9451503101003983）（2009.10-2011.10）" },
    ];

    return (
        <div className="theory">
            <div className="content-wrapper">
                <section className="papers">
                    <h2>学术论文：</h2>
                    <ul>
                        {papers.map((paper) => (
                            <li key={paper.id}>
                                <p>
                                    [{paper.id}]  {paper.title}
                                </p>
                            </li>
                        ))}
                    </ul>
                </section>
                <section className="books">
                    <h2>学术专著：</h2>
                    <ul>
                        {books.map((book) => (
                            <li key={book.id}>
                                <p>
                                    [{book.id}]  {book.title}
                                </p>
                            </li>
                        ))}
                    </ul>
                </section>
                <section className="projects">
                    <h2>科研项目：</h2>
                    <ul>
                        {projects.map((project) => (
                            <li key={project.id}>
                                <p>
                                    [{project.id}] {project.title}
                                </p>
                            </li>
                        ))}
                    </ul>
                </section>
            </div>
        </div>
    );
}

export default Theory;