import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import logo from '../../assets/logo.png';
import './ValuationResult.css';

function Header() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const value = searchParams.get('value');
    return (
        <>
            <header className="header">
                <img src={logo} alt="Valuengine Logo" className="logo" />
                <div className="headewwr" >估值结果</div>
                <button className="back-button" onClick={() => window.history.back()}>返回</button>
            </header>

            <div className="home">

                <div className="main-buttons2">
                    <a className="main-button11">{value}</a>
                </div>

                <div className="disclaimer">
                    <a style={{ fontWeight: 700 }}>免责声明</a>：本公司咨询服务基于客户提供的信息，并按照专业流程进行评估。我们将依据所掌握的信息基础上，尽力做到客观、准确和可信赖，但任何此类分析都基于一定的假设、历史信息和理论推演，都会面临不确定性，无法保证其完全准确性。因此，本公司提供的任何咨询意见、观点和报告只具有参考价值，不作为具体投融资建议的一部分，不成为具体企业上市价实际决策的依据，任何损失作出的投融资决策后果均由客户自己承担。
                </div>
            </div>
        </>
    );
}

export default Header;