import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';  // 全局样式
import App from './App';  // 导入主组件
import 'bootstrap/dist/css/bootstrap.min.css';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
