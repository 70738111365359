import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css';
import Footer from '../../components/Footer';

function Home() {
    return (
        <div className="home">

            <div className="home_" >
                <h1 className="title">AI 驱动的创业型企业估值系统</h1>

                <div className="main-buttons">
                    <Link to="/entrepreneur" className="main-button">我是创业者</Link>
                    <Link to="/investor" className="main-button">我是投资者</Link>
                </div>
            </div>

            <div className="disclaimer">
                <a style={{ fontWeight: 700 }}>免责声明</a>：本公司咨询服务基于客户提供的信息，并按照专业流程进行。我们将在所掌握的信息基础上，尽力做到客观、准确和可信赖，但任何此类分析都基于一定的假设、历史信息和理论推演，都会面临不确定性，无法保证其完整性和精确性。因此，本公司提供的任何咨询意见、观点和报告只具有参考价值，不作为具体投融资建议的一部分，不成为具体企业股份买卖决策的依据，任何据此作出的投融资决策后果均由客户自己承担。
            </div>
            <Footer />
        </div>
    );
}

export default Home;